import React, { useState } from "react";
import _ from "lodash";
import AttributesFilterItem from "./AttributesFilterApp/AttributesFilterItem";

global._ = _; // Import lodash as global

function AttributesFilterApp(props) {

    const [selectedItems, setSelectedItems] = useState([]);

    React.useEffect(() => {

        // console.log(selectedItems);

        const dataToSend = {
            product_attributes: selectedItems
        };

        doRequest(dataToSend).then((response) => {

            const element = document.getElementById("products-grid-wrapper");
            element.innerHTML = response.html;

        });
    });

    const handleAttributeClick = (attribute) => {

        // console.log("handleAttributeClick");
        // console.log(attribute);

        let tmp = selectedItems;

        if (isSelected(attribute)) {

            _.remove(tmp, (i) => {
                return i.key === attribute.key;
            });
        }
        else {
            tmp = _.concat(tmp, attribute);
        }

        // Force re-render
        setSelectedItems(_.clone(tmp));
    };

    const doRequest = async (data) => {

        const url = "/products/attribute_filter_query";
        const csrfToken = document.querySelector("[name=\"csrf-token\"]").content;
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "X-CSRF-Token": csrfToken
            },
            body: JSON.stringify(data, "", 2)
        });

        return response.json();
    };

    const isSelected = (attribute) => {

        return _.findIndex(selectedItems, (i) => {
            return i.key === attribute.key;
        }) > -1;
    };

    const renderAttributeFilterItems = () => {

        return _.map(props.product_attributes, (attribute, index) => {

            return <AttributesFilterItem
                key={`${ index }`}
                attribute={attribute}
                selected={isSelected(attribute)}
                onClick={() => {
                    handleAttributeClick(attribute);
                }}
            />;
        });
    };

    const clearSelections = () => {
        setSelectedItems([]);
    };

    return <div>
        {renderAttributeFilterItems()}
        <br />
        <a
            href={"#"}
            onClick={(event) => {
                event.stopPropagation();
                event.nativeEvent.stopImmediatePropagation();
                clearSelections();
            }}
        >
            Clear
        </a>
    </div>;
}

export default AttributesFilterApp;