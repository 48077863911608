import React from "react";

function AttributesFilterItem(props) {

    const getLinkClass = () => {

        if (props.selected) {
            return "btn btn-sm btn-chip mr-2 mb-2 btn-primary";
        }
        else {
            return "btn btn-sm btn-chip mr-2 mb-2 btn-outline-secondary";
        }
    };

    const getIconClass = () => {

        if (props.selected) {
            return "fa fa-check-circle";
        }
        else {
            return "fa fa-circle-o";
        }
    };

    return <a
        // href="#"
        onClick={() => {
            props.onClick()
        }}
        className={getLinkClass()}
    >
        <i
            className={getIconClass()}
        />
        &nbsp;
        {props.attribute.name}
    </a>;
}

export default AttributesFilterItem;